import PrePostTasqTemplate from '@/interfaces/schedule/PrePostTasqTemplate';


const mapPrePostTasqTemplate = (t): PrePostTasqTemplate => {

  t = JSON.parse(t)
  const prePost = {
    id: t.ID,
    tasqType: t.TasqType,
	tasqTitle: t.TasqTitle,
	operator: t.Operator,
	time: t.time,
	tasqSummary: t.TasqSummary
  };

  return prePost;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  mapPrePostTasqTemplate,
};
